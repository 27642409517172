import React from "react"
import Loadable from "@loadable/component"
import { graphql, useStaticQuery } from "gatsby"
const OwlCarousel = Loadable(() => import("react-owl-carousel"))

function Partners() {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          dir: { regex: "/img/partners/" }
        }
      ) {
        edges {
          node {
            relativePath
          }
        }
      }
    }
  `)

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="margin-50px-bottom xs-margin-40px-bottom text-center">
          <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
            Our partnerships
          </h4>
          <p className="text-center sm:px-40 text-xl mt-3">
            Our longstanding partnerships with industry leaders, municipalities,
            HUD and local housing authorities, have provided Stonebridge Global
            Partners with the platform to become one of the fastest growing
            affordable housing developers.
          </p>
        </div>
        <div className="mt-6 lg:mt-8">
          <OwlCarousel
            className="owl-theme margin-35px-bottom"
            loop
            margin={10}
            items={4}
            autoplay
            autoplayTimeout={3000}
            dots
            autoHeight={false}
            responsive={{
              0: {
                items: 1,
              },
              600: {
                items: 3,
              },
              1000: {
                items: 4,
              },
            }}
          >
            {data.allFile.edges.map(img => {
              return (
                <div
                  key={img.node.relativePath}
                  className="col-span-1 flex justify-center py-8 px-8 bg-gray-200 rounded-md"
                >
                  <img
                    className=" !object-contain !h-32 w-full"
                    src={`/img/${img.node.relativePath}`}
                  />
                </div>
              )
            })}
          </OwlCarousel>
        </div>
      </div>
    </div>
  )
}

export default Partners
