import React from "react"
import PageBanner from "../components/common/PageBanner"
import Partners from "../components/common/Partners"
import Layout from "../components/navigation/Layout"

const AboutUs = () => {
  return (
    <Layout>
      <PageBanner
        title=""
        backgroundImg="/img/backgrounds/headers/office1.jpg"
      />
      <div className="container" style={{ paddingTop: 50 }}>
        <div className="row margin-90px-bottom md-margin-70px-bottom smd-margin-50px-bottom align-items-center">
          <div className="col-md-6 order-2 order-md-1">
            <div className="padding-40px-right sm-no-padding-right">
              <h4 className="font-size38 sm-font-size26 line-height-50 sm-line-height40 font-weight-500 margin-20px-bottom">
                Our story
              </h4>
              <p className="margin-20px-bottom width-90 sm-width-100">
                Stonebridge Global Partners is a real estate investment company
                dedicated to the preservation of affordable housing across the
                United States. We acquire, develop and operate multifamily and
                elderly project-based section 8, LIHTC and workforce housing,
                with a focus in creating value across our communities. We aspire
                to provide quality affordable housing to enhance the lives and
                wellbeing of our residents.
              </p>
              <p className="margin-20px-bottom width-90 sm-width-100">
                At Stonebridge, its more than just buying real estate, it’s
                about building community!
              </p>
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2 xs-margin-30px-bottom">
            <div>
              <img className="rounded-2xl" src="/img/about1.jpg" alt="" />
            </div>
          </div>
        </div>
        <div className="row margin-90px-bottom md-margin-70px-bottom smd-margin-50px-bottom align-items-center">
          <div className="col-md-6 xs-margin-30px-bottom">
            <div>
              <img className="rounded-2xl" src="/img/about4.jpg" alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="padding-40px-left sm-padding-10px-left">
              <h4 className="font-size38 sm-font-size26 line-height-50 sm-line-height40 font-weight-500 margin-20px-bottom">
                Our success
              </h4>
              <p className="margin-50px-bottom sm-margin-30px-bottom width-90 sm-width-100">
                We strive for superior results in every aspect of our business,
                from selective acquisitions to proactive property management,
                and personalized investor relations.
              </p>
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                <div className="">
                  <div className="text-center">
                    <h5 className="countup font-size38 xs-font-size30">34</h5>
                    <p className="font-size18 xs-font-size16 no-margin-bottom text-extra-dark-gray">
                      Communities
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="text-center">
                    <h5 className="countup font-size38 xs-font-size30">
                      4,721
                    </h5>
                    <p className="font-size18 xs-font-size16 no-margin-bottom text-extra-dark-gray">
                      Homes
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="text-center">
                    <h5 className="countup font-size38 xs-font-size30">
                      10,000+
                    </h5>
                    <p className="font-size18 xs-font-size16 no-margin-bottom text-extra-dark-gray">
                      families
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="text-center">
                    <h5 className="countup font-size38 xs-font-size30">
                      40,000+
                    </h5>
                    <p className="font-size18 xs-font-size16 no-margin-bottom text-extra-dark-gray">
                      lives
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Partners />
      </div>
    </Layout>
  )
}

export default AboutUs
